import { render, staticRenderFns } from "./RegistrationNavToApp.vue?vue&type=template&id=7e28983a&scoped=true"
import script from "./RegistrationNavToApp.vue?vue&type=script&lang=ts"
export * from "./RegistrationNavToApp.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e28983a",
  null
  
)

export default component.exports