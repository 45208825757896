
import Vue from "vue";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { Actions, Mutations } from "@/store/models";
import RegistrationCreatePassword from "@/components/registration/RegistrationCreatePassword.vue";
import { companies, setAuthTokenHeader, setHeaders } from "@/api";
import RegistrationNavToApp from "@/components/registration/RegistrationNavToApp.vue";

export default Vue.extend({
  name: "JoinTeam",
  components: {
    RegistrationCreatePassword,
    RegistrationNavToApp,
  },
  data() {
    return {
      logo: require("/src/assets/gssi-emblem.svg"),
      image: require("/src/assets/sidebar-bkground.png"),
      token: this.$route.query.token as string,
      team: this.$route.query.team_name as string,
      username: this.$route.query.user_email as string,
      password: "",
      isSignedIn: false,
    };
  },
  computed: {},
  created() {
    this.username = this.username.replaceAll(" ", "+");
  },
  methods: {
    async createPassword(password: string) {
      try {
        setAuthTokenHeader(this.token);
        await companies.nlc.setPassword(password);
        this.password = password;
        this.login();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    login() {
      this.$store.commit(Mutations.SetIsLoading, true);

      const authenticationDetails = new AuthenticationDetails({
        Username: this.username,
        Password: this.password,
      });
      const userPool = new CognitoUserPool({
        UserPoolId: process.env.VUE_APP_USER_POOL_ID,
        ClientId: process.env.VUE_APP_CLIENT_ID,
      });
      const cognitoUser = new CognitoUser({
        Username: this.username,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          const { initialized } = await this.$store.dispatch(
            Actions.Init,
            (result.getAccessToken() as any).jwtToken
          );
          if (initialized) {
            this.isSignedIn = true;
          } else {
            this.$store.dispatch(Actions.DisplayError, "Cannot Authorize User");
          }
          this.$store.commit(Mutations.SetIsLoading, false);
        },
        onFailure: (err) => {
          this.$store.dispatch(
            Actions.DisplayError,
            err.message || JSON.stringify(err)
          );
          this.$store.commit(Mutations.SetIsLoading, false);
        },
      });
    },
  },
});
