
import { Actions } from "@/store/models";
import { isValidPassword } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "RegistrationCreatePassword",
  props: {
    username: String,
    team: String,
  },
  data() {
    return {
      password: "",
      verifyPassword: "",
    };
  },
  computed: {},
  methods: {
    submit() {
      if (!this.password || !this.verifyPassword) {
        return;
      }
      this.password = this.password.trim();
      this.verifyPassword = this.verifyPassword.trim();

      if (!isValidPassword(this.password)) {
        this.$store.dispatch(
          Actions.DisplayError,
          `Password validation failed:     
          - Must be at least 8 characters
          - Must contain at least 1 number
          - Must contain at least 1 upper case character
          - Must contain at least 1 lower case character
          - Must contain at least 1 special character`
        );
        return;
      }

      if (this.password !== this.verifyPassword) {
        this.$store.dispatch(Actions.DisplayError, "Password does not match");
        return;
      }

      this.$emit("submit", this.password);
      this.password = "";
      this.verifyPassword = "";
    },
  },
});
