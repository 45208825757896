
import Vue from "vue";

export default Vue.extend({
  name: "RegistrationNavToApp",
  props: {
    isMember: Boolean
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    toDashboard() {
      this.$router.replace("/dashboard");
    },
    toAccount() {
      this.$router.replace("/accountmanagement");
    },
    toProfile() {
      this.$router.replace("/users/" + this.$store.state.associate.id );
    }
  },
});
